@import "~@optimizely/design-tokens/dist/fonts/fonts.css";
@import "~@optimizely/design-tokens/dist/css/colors.css";
@import "~@optimizely/design-tokens/dist/css/typography.css";
@import "~@optimizely/design-tokens/dist/css/colors.css";

body {
  --global-bar-height: 40px;
  margin: 0;
  font-family: var(--axiom-font-families-inter);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}