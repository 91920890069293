.product-card {
  display: flex;
  height: 245px;
  flex-direction: column;
  background: #fff;
  h4 {
    width: auto;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
  &__footer {
    margin-top: 10px;
  }
}