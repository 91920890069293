@import '@optimizely/design-tokens/dist/scss/colors';
@import '@optimizely/design-tokens/dist/scss/typography';
@import '../../variables.scss';

.product-card {
  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__dropdown {
    margin-top: 0;
    border-radius: 0 0 $size-small $size-small;
    box-shadow: none;
    border-top: none;
  }

  &__product-name {
    justify-content: center;
    display: flex;
    text-align: center;
  }

  &__search {
    position: static;
    padding: $size-small;
    background: $neutral-colors-white;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  &__no-results {
    white-space: pre-wrap;
    padding: 0;
    background: $neutral-colors-white;
  }

  &__popover {
    background:$brand-purple-dark;
    color: $neutral-colors-white;
    &::before, &::after {
      border-right-color: $brand-purple-dark !important;
    }
  }

  &__list-item {
    display: flex;
    div:first-child  {
      width: 100%;
      display: flex;
      align-items: center;
    }
    svg {
      margin-left: auto;
    }

  }

  &__container {
    display: flex;
    flex-direction: column;
    font-size: $font-sizes-body;
    font-weight: $body-font-weight;
    line-height: $line-heights-subhead;
    height: 140px;
    &--disabled {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $grey-extra-light;
      border-radius: $size-small;
      border: 1px solid $smoke-dark;
      color: $grey-dark;
      svg {
        filter: invert(43%) sepia(10%) saturate(18%) hue-rotate(327deg) brightness(97%) contrast(86%);
      }
    }
    &--active {
      button {
        cursor: pointer;
        background: $brand-blue-light !important;
        color: $brand-blue-dark !important;
        border: 1px solid $brand-blue-dark !important;
        svg {
          filter: invert(11%) sepia(100%) saturate(7493%) hue-rotate(235deg) brightness(102%) contrast(109%);
        }
      }
    }

    button {
      padding: 12px;
      border: 1px solid $smoke-dark;
      border-radius: $size-small;
      width: 100%;
      background: #fff;
      height: 100%;

      &:hover, &:focus-within, &:active {
        cursor: pointer;
        background: $brand-blue-light;
        color: $brand-blue-dark !important;
        border: 1px solid $brand-blue-dark;

        svg {
          filter: invert(11%) sepia(100%) saturate(7493%) hue-rotate(235deg) brightness(102%) contrast(109%);
        }
      }
    }
  }
}