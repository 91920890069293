.dashboard-layout {
    background: #FAFAFA;
    display: grid;
    gap: 24px;
    grid-template-columns: 3fr 1fr; /* Left column takes more space, right column less */
    min-height: calc(100vh - var(--global-bar-height) - 64px);
    padding: 32px;

    &__main-content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive card layout */
        gap: 2px;
    }

    &__help-column {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
    }
}