@import '@optimizely/design-tokens/dist/scss/colors';
@import '@optimizely/design-tokens/dist/scss/typography';

.help-and-resources {
  display: flex;
  flex-direction: column;
  border: 1px solid $smoke-dark;
  background: $neutral-colors-white;
  border-radius: 4px;
  padding: 24px 16px;
  margin-bottom: 16px;
  flex-grow: 1;

  &__heading {
    pointer-events: none;
  }

  &__link {
    display: flex;
    align-items: center;
    width: max-content;
    margin: 4px 0 4px 0;

    svg {
      margin-right: 4px;
    }
    &:hover{
      cursor: pointer;
      color: $brand-blue-dark;
      border: 1px solid $brand-blue-dark;
      svg {
        filter: invert(11%) sepia(100%) saturate(7493%) hue-rotate(235deg) brightness(102%) contrast(109%);
      }
    }
  }

}