@import '@optimizely/design-tokens/dist/scss/colors';

.loading-indicator {
  padding-left: initial;
  display: flex;
  white-space: nowrap;
  font-size: .875rem;
  list-style: none;
  justify-content: center;
  li {
    animation: dot-flashing 2s infinite;
    background: $brand-blue-dark;
    border-radius: 50%;
    display: inline-block;
    height: 7px;
    margin: 0 3px;
    opacity: 0;
    width: 7px;
    &:nth-child(2) {
      animation-delay: .2s;
    }
    &:nth-child(3) {
      animation-delay: .4s;
    }
  }
}

@keyframes dot-flashing {
  0%, 100% {
    opacity:0;
  }
  50% {
    opacity: 1;
  }
}